@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap");

$imageWidth: 35vw;

.left {
  // background-color: red;
  margin-left: 0;
  margin-right: auto;
  width: $imageWidth;
}

.right {
  // background-color: blue;
  // align-content: flex-end;
  margin-left: auto;
  margin-right: 0;
  width: $imageWidth;
}

.imagecard {
  width: 100%;
  // padding: 0 5vw;
  // margin: 5vw;
  font-family: "Poppins", sans-serif;
  padding-bottom: 20px;
  // transition: 0.25s;

  img {
    width: $imageWidth;
  }
  h1 {
    font-weight: 500;
    font-size: 1.5rem;
    // top: 0.25vw;
    // position: relative;
  }

  h2 {
    font-weight: 500;
    color: #6196cc;
    font-size: 0.9rem;
    // top: -10px;
  }

  .inset {
    position: relative;
    top: -37px;
    left: 50px;
  }

  .arrow {
    filter: invert(73%) sepia(11%) saturate(5224%) hue-rotate(182deg) brightness(82%) contrast(93%);
    width: 0.8rem;
    top: 1px;
    position: relative;
    // top: 0.1vw;
    left: 0.25vw;
  }
}

@media screen and (max-width: 992px) {
  .left,
  .right {
    width: 100%;
  }

  .imagecard {
    img {
      width: 100%;
    }
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 0.9rem;
      position: relative;
      top: -10px;
    }
    .arrow {
      width: 0.8rem;
      top: 1px;
    }
    .inset {
      top: -37px;
      left: 50px;
    }
  }
}

@media screen and (max-width: 600px) {
  .left,
  .right {
    width: 100%;
  }

  .imagecard {
    img {
      width: 100%;
    }
    h1 {
      font-size: 1.1rem;
    }
    h2 {
      font-size: 0.6rem;
      position: relative;
      top: -10px;
    }
    .arrow {
      width: 0.45rem;
      top: 1px;
    }
    .inset {
      top: -33px;
      left: 50px;
    }
  }
}
