@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap");

.heading {
  width: 100%;
  user-select: none;
  padding-bottom: 50px;
  overflow: visible;

  .outline {
    -webkit-text-stroke: 0.2vw #6196cc;
    color: rgba(0, 0, 0, 0);
  }

  h1 {
    word-spacing: 2px;
    font-size: 7.5vw;
    font-family: "Syncopate", sans-serif;
    height: 5.3vw;
    text-align: end;
    // padding-right: 5vw;
  }

  h2 {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    text-align: start;
    // padding-left: 5vw;
    font-size: 1vw;
  }
}

@media screen and (max-width: 992px) {
  .heading {
    padding-bottom: 30px;
    h1 {
      position: relative;
      font-size: 4rem;
      height: 45px;
      top: -30px;
    }
    h2 {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .heading {
    padding-bottom: 20px;
    h1 {
      position: relative;
      font-size: 2.5rem;
      height: 33px;
      top: -20px;
    }
    h2 {
      font-size: 0.65rem;
    }
  }
}
