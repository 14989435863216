.cursor-dot,
.cursor-dot-outline {
  z-index: 100;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.cursor-dot {
  width: 1vw;
  height: 1vw;
  background-color: rgb(255, 255, 255);
}

.cursor-dot-outline {
  width: 5vw;
  height: 5vw;
  animation: rotation 10s infinite linear;
  //   background-color: rgba(255, 255, 255, 0.301);
}

.hidden {
  visibility: hidden;
}

// #region animations

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

// #endregion
