@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:ital@1&display=swap");

@font-face {
  font-family: "Novecento";
  src: url("../fonts/novecento_bold-webfont.woff");
}

$textOutline: 10px;

* {
  //   width: 100vw;
  //   height: 100vh;
  font-family: "Inter", serif;

  // cursor: none;

  margin: 0;
  padding: 0;
  color: #d9d7e0;
  // overflow-x: clip;
  position: relative;
  // font-family: "Novecento", "Inter", sans-serif;
  // overflow-x: hidden;
}

html {
  background-color: #1b191f;
  scroll-behavior: smooth;
  // background-color: #0d0d0d;
  overflow-x: hidden;
  -webkit-overflow-scrolling: none;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.title {
  font-size: 15vw;

  font-style: italic;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  color: #000;
  z-index: -15;
  position: fixed;
}

#footer {
    padding: 20px;
    a {
        color: #6196cc;
    }
}

.scroll-indicator {
  z-index: 100;
  // margin: auto 10px;
  width: 40px;
  position: relative;
  top: -45px;
  transition: all 0.25s;
  .arrow {
    width: 30px;
  }
}
.scroll-indicator:hover {
  top: -25px;
  // background-color: red;
}

::-moz-selection {
  /* Code for Firefox */
  color: #d9d7e0;
  background: #6196cc;
}

::selection {
  color: #d9d7e0;
  background: #6196cc;
}

// .navbar {
// top: 0;
// left: 0;
// position: absolute;
// font-style: ;

// padding: 0.5vw;
// }

.logo-motion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.text-logo {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  width: 60%;
  height: 30%;
}

@media screen and (max-width: 600px) {
  .text-logo {
    width: 90%;
  }
}

.spacer {
  height: 150vh;
  width: 10px;
  width: 100vw;
  // position: relative;
}

#work {
  // margin-top: 778px;
  top: 0;
  position: relative;
  padding-bottom: 10vw;
}

#landing-page {
  position: relative;
  top: 0;
  // margin-top: 100vhs;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
}
