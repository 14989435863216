.gallery {
  line-height: 0;

  -webkit-column-count: 4;
  -webkit-column-gap: 0px;
  -moz-column-count: 4;
  -moz-column-gap: 0px;
  column-count: 4;
  column-gap: 5px;
  padding-bottom: 20px;
}

.images {
  width: 100% !important;
  height: auto !important;
  padding: calc(5px / 2) 0px;
}

.images:hover {
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    max-width: 90%;
    max-height: 90%;
    // margin: 60px auto;
  }
}

@media screen and (max-width: 1200px) {
  .gallery {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media screen and (max-width: 1000px) {
  .gallery {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media screen and (max-width: 800px) {
  .gallery {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
// @media screen and (max-width: 400px) {
//   .gallery {
//     -moz-column-count: 1;
//     -webkit-column-count: 1;
//     column-count: 1;
//   }
// }
