@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap");

.button-link {
  // padding-left: 5vw;
  margin-left: calc(90vw - 35vw);
  width: 35vw;
  top: 25vw;
  position: relative;
  z-index: 2;
  a {
    // display: flex;
    width: 35vw;
    display: block;
    padding: 1vw 1vw 1vw;
    font-family: "Poppins", sans-serif;
    font-size: 1vw;
    text-decoration: none;
    border-bottom: 1px solid #6196cc;
    transition: 0.2s ease-in-out;
    flex-grow: 1;
  }

  .arrow {
    filter: invert(100%);
    width: 0.9vw;
    position: relative;
    top: 0.2vw;
    // left: 0.5vw;
    left: 24.25vw;
  }

  a:hover {
    background-color: white;
    transform: scale(0.98);
    color: black;
    border: none;
    border-bottom: 1px solid rgb(255, 255, 255);

    .arrow {
      filter: none;
    }
  }
}

@media screen and (max-width: 992px) {
  .button-link {
    display: none;
  }
}
