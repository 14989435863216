@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap");

.scrolling-header {
  position: relative;
  overflow-x: hidden;

  padding: 20vh;

  // transform: translate(0, -25%);

  h1 {
    font-family: "Syncopate", sans-serif;
    font-size: 120px;
    word-spacing: 2px;
    height: 90px;
    word-wrap: normal;
    white-space: nowrap;
    padding-left: 30px;
    // -webkit-animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
    // animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
    // padding-left: 1vw;
  }

  .filled {
    color: #d9d7e0;
  }

  .outline {
    // position: relative;
    -webkit-text-stroke: 0.2vw #6196cc;
    color: rgba(0, 0, 0, 0);
    z-index: 20;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: visible;
    // z-index: -1;
  }

  .block {
    width: auto;
    // .slide-up {

    // }
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    // position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    // top: 27.5vw;
    // ;eft: -50vh;

    width: 30vw;
    z-index: 1;
    // z-index: -10;
  }
}

@media screen and (max-width: 992px) {
  .scrolling-header {
    padding: 200px;
    h1 {
      font-size: 90px;
      height: 65px;
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 600px) {
  .scrolling-header {
    padding: 100px;
    h1 {
      font-size: 40px;
      height: 35px;
      padding-left: 15px;
    }
  }
}

@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
