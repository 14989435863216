@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap");

.list-object {
  // margin-left: 5vw;
  // margin: 0.5vw 5vw;
  width: 50vw;
  padding: 1.5vw;
  //   height: 5vw;
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: row;
  //   align-items: flex-start;
  align-content: stretch;

  font-family: "Poppins", sans-serif;
  //   background-color: blue;
  border-bottom: 1px solid rgb(127, 127, 127);
  transition: 0.25s;
  text-decoration: none;
  text-align: left;

  h1 {
    font-size: 1vw;
    font-weight: 400;
    // flex-grow: 1;
  }
  h2 {
    font-size: 1vw;
    font-weight: 400;
    color: rgb(127, 127, 127);
    // flex-grow: 1;
  }

  .list-title {
    width: 50%;
    flex-grow: 2;
  }
  .list-description {
    flex-grow: 1;
  }
  .list-year {
    flex-grow: 0.5;
  }

  .arrow {
    filter: invert(100%);
    width: 0.9vw;
    position: relative;
    // flex-grow: 1;
  }
}

.list-object:hover {
  transform: scale(0.98);
  background-color: #d9d7e0;
  border-bottom: 1px solid #d9d7e0;

  h1 {
    color: #1b191f;
  }

  .arrow {
    filter: none;
  }
}
