@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap");

// $content-width: 25vw;

.content-container {
  margin: 0vw 5vw;
}

.text-container {
  margin: 0px 25vw;
  font-family: "Inter", serif;
  // text-align: center;

  .embed-video {
    margin-bottom: 15px;
  }

  h1 {
    font-size: 3.25rem;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.25s;
    // -webkit-animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
    // animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
  }
  h1:hover {
    opacity: 0.9;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    margin-top: 40px;
    // margin-top: 10px;
  }
  p {
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 150%;
    color: #d9d7e0cc;
  }
  .content-img {
    width: calc(50% - 20px);
    // width
    cursor: pointer;
    z-index: 10;
    margin-bottom: 20px;
  }
  .thumbnail-img {
    width: 30%;
    cursor: pointer;
    z-index: 10;
    margin-bottom: 20px;
  }
  .divider-img {
    width: 100%;
    margin: 10px 0;
    cursor: pointer;
    z-index: 10;
  }
  .right {
    margin: 20px;
    // margin-right: 0;
    margin-top: 0;
    float: right;
    clear: right;
  }
  .left {
    margin: 20px;
    margin-left: 0;
    margin-top: 10px;
    float: left;
    clear: left;
  }
  .inline {
    width: calc(50% - 20px);
    margin: 0;
    margin-bottom: 20px;
    object-fit: cover;
  }
  .line {
    width: calc(25% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    object-fit: cover;
  }
  .threexline {
    width: calc(33% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    object-fit: cover;
  }
  a {
    color: #6196cc;
    text-decoration: none;

    transition: 0.1s;
  }
  a:hover {
    color: #6196ccd2;
  }
}

// body {
//   background-color: tan;
// }

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  // body {
  //   background-color: blue;
  // }
  .text-container {
    margin: 0px 15vw;
    h1 {
      font-size: 2.5rem;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 2rem;
      margin-bottom: 12.5px;
    }
    p {
      font-size: 0.9rem;
      margin-bottom: 12.5px;
    }
    .embed-video {
        margin-bottom: 12.5px;
    }
    .line {
        width: calc(50% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
        object-fit: cover;
      }
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  // body {
  //   background-color: olive;
  // }
  .text-container {
    margin: 0px 20px;
    h1 {
      font-size: 2rem;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 20px;
      margin-bottom: 7.5px;
    }
    p {
      font-size: 0.8rem;
      margin-bottom: 12.5px;
    }
    .embed-video {
        margin-bottom: 12.5px;
      }
    .left {
      margin: 10px;
      margin-left: 0;
    }
    .right {
      margin: 10px;
      margin-right: 0;
    }
    .divider-img {
      margin: 5px 0;
    }
    .content-img {
      width: 100%;
      cursor: pointer;
    }
  }
}
